import { Checkbox, Stack } from '@mui/material';
import { MAPPING_TYPES, REVIEW_STATUSES } from 'components/resources/Mapping/constants';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import {
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  SimpleForm,
  useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router';

const toChoices = (types) => Object.keys(types).map((type) => ({ name: type, id: type }));

const mappingTypeOptions = toChoices(MAPPING_TYPES);
const reviewStatusOptions = toChoices(REVIEW_STATUSES);

const timestampToDDMMYYYY = (timestamp) => {
  const val = moment(timestamp);
  return val.isValid() ? val.format('DD/MM/YYYY') : null;
};

const getMergedDescription = (description, reference) => {
  if (!reference) {
    return description;
  }
  if (description?.includes(reference)) {
    return description;
  }
  return [description, reference].join(' | ');
};

const TransactionOption = () => {
  const record = useRecordContext();
  const description = record?.data?.details?.description;
  const reference = record?.data?.details?.paymentReference;
  const mergedDescription = getMergedDescription(description, reference);
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <p>
        <strong>
          {mergedDescription}
        </strong>
      </p>
      <p>{moment(record.timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')}</p>
    </Stack>
  );
};

const matchSuggestion = (filter, t) => (
  t?.id?.toLowerCase() === filter?.toLowerCase()
  || t?.data?.details?.description?.toLowerCase()?.includes(filter?.toLowerCase())
  || t?.data?.details?.paymentReference?.toLowerCase()?.includes(filter?.toLowerCase())
);

const inputText = (t) => ([
  getMergedDescription(
    t?.data?.details?.description,
    t?.data?.details?.paymentReference,
  ),
  moment(t.timestamp * 1000).format('DD/MM/YYYY HH:mm:ss'),
].join(' | '));

const MappingCreate = () => {
  const [isStrict, setStrict] = useState(true);
  const location = useLocation();

  const handleChangeStrict = (event) => setStrict(event.target.checked);

  const defaultTransactionId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('transaction');
  }, [location]);

  const defaultDocumentId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('document');
  }, [location]);

  const defaultAmount = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return +params.get('amount');
  }, [location]);

  const defaultMappingType = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('mapping') || 'INVOICE_PAYMENT';
  }, [location]);

  const defaultValues = useMemo(() => ({
    transactionId: defaultTransactionId,
    mappingType: defaultMappingType,
    reviewStatus: 'QUEUED',
    items: [{
      documentId: defaultDocumentId,
      amount: defaultAmount,
    }],
  }), [
    defaultDocumentId,
    defaultTransactionId,
    defaultAmount,
    defaultMappingType,
  ]);

  return (
    <Create>
      <SimpleForm defaultValues={defaultValues}>
        <Stack p={2}>
          <Stack direction="row" alignItems="center">
            <Checkbox checked={isStrict} onChangeCapture={handleChangeStrict} />
            <p>STRICT MATCHING MODE</p>
          </Stack>
          <ReferenceInput source="transactionId" reference="statement/transactions">
            <AutocompleteInput
              optionText={<TransactionOption />}
              inputText={inputText}
              matchSuggestion={matchSuggestion}
              filter={(t) => !t._mapping}
            />
          </ReferenceInput>
          <Stack direction="row" spacing={2}>
            <SelectInput source="mappingType" choices={mappingTypeOptions} />
            <SelectInput source="reviewStatus" choices={reviewStatusOptions} />
            <BooleanInput source="confirmed" />
            <BooleanInput source="locked" />
          </Stack>
          {/* <SelectArrayInput source="tags" choices=/> */}
          <ArrayInput source="items">
            <SimpleFormIterator inline>
              <NumberInput source="amount" fullWidth />
              <ReferenceInput
                source="documentId"
                reference="documents"
                filter={{
                  ...(isStrict && { suggestedTransaction: defaultTransactionId }),
                }}
              >
                <AutocompleteInput
                  optionText={(doc) => [
                    doc?.document?.issuerName,
                    doc?.document?.id,
                    timestampToDDMMYYYY(doc?.document?.issueDate),
                    [
                      doc?.document?.total,
                      doc?.document?.currency,
                    ].join(' '),
                  ].join(' | ')}
                  filter={(doc) => !!doc?.document?.id}
                  style={{ width: '100%' }}
                />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="note" multiline />
        </Stack>
      </SimpleForm>
    </Create>
  );
};

export default MappingCreate;
