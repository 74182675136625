import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  FunctionField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { getBookkeepingApiUrl, TOKEN_KEY } from 'utils/api';

const PaymentBatchList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <TextField source="status" label="Status" />
      <TextField source="type" label="Type" />
      <TextField source="fileType" label="Type" />
      <TextField source="meta.year" label="Year" />
      <TextField source="meta.month" label="Month" />
      <TextField source="createdBy.name" label="Created By" />
      <FunctionField
        render={(record) => (
          <If condition={record.status === 'success'}>
            <a
              href={`${getBookkeepingApiUrl()}/downloads/${record.id}/file?token=${localStorage.getItem(TOKEN_KEY)}`}
              download={`${record.id}.zip`}
            >
              Download
            </a>
          </If>
        )}
      />
      <ShowButton />
    </Datagrid>
  </List>
);

export default PaymentBatchList;
