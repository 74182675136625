import { Stack } from '@mui/material';
import EditCreateForm from 'components/resources/Contractor/EditCreateForm';
import React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';

const defaultValues = {};

const ContractorEdit = () => (
  <Edit>
    <SimpleForm defaultValues={defaultValues}>
      <EditCreateForm />
    </SimpleForm>
  </Edit>
);

export default ContractorEdit;
