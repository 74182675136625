import React from 'react';
import {
  ChipField, BooleanField, NumberField,
  Show, SimpleShowLayout, TextField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const ContractorShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="label" />
      <TextField source="email" />
      <TextField source="legalName" />
      <TextField source="defaultContractID" />
      <TextField source="registeredAddress.line1" />
      <TextField source="registeredAddress.line2" />
      <TextField source="registeredAddress.city" />
      <TextField source="registeredAddress.state" />
      <TextField source="registeredAddress.country" />
      <TextField source="registeredAddress.zipCode" />
      <TextField source="accountNumber" />
      <TextField source="swiftCode" />
      <TextField source="taxID" />
      <TextField source="registerID" />
      <ChipField source="serviceType" />
      <ChipField source="legalPriceType" />
      <BooleanField source="billingSameAsRegistered" />
      <TextField source="billingAddress.line1" />
      <TextField source="billingAddress.line2" />
      <TextField source="billingAddress.city" />
      <TextField source="billingAddress.state" />
      <TextField source="billingAddress.country" />
      <TextField source="billingAddress.zipCode" />
      <NumberField source="salaryHourly" />
      <NumberField source="salaryMonthly" />
      <NumberField source="taxPercent" />
      <NumberField source="taxFixed" />
      <ChipField source="defaultPaymentType" />
    </SimpleShowLayout>
  </Show>
);

export default ContractorShow;
