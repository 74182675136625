import React, { useMemo } from 'react';

import {
  Admin,
  Resource,
  CustomRoutes,
  fetchUtils,
} from 'react-admin';
import { Route } from 'react-router';
import './AdminContainer.scss';
import { useAuthContext, usePermissions } from 'providers/AuthProvider';
import jsonServerProvider from 'ra-data-json-server';

import { getBookkeepingApiUrl } from 'utils/api';
import TransactionList from 'components/resources/Transaction/List';
import TransactionShow from 'components/resources/Transaction/Show';
import DocumentShow from 'components/resources/Document/Show';
import DocumentEdit from 'components/resources/Document/Edit';
import DocumentList from 'components/resources/Document/List';
import MappingList from 'components/resources/Mapping/List';
import MappingShow from 'components/resources/Mapping/Show';
import MappingCreate from 'components/resources/Mapping/Create';
import MappingEdit from 'components/resources/Mapping/Edit';

import DownloadList from 'components/resources/Download/List';
import DownloadShow from 'components/resources/Download/Show';
import DownloadCreate from 'components/resources/Download/Create';

import InvoiceBatchList from 'components/resources/InvoiceBatch/List';
import InvoiceBatchShow from 'components/resources/InvoiceBatch/Show';
import InvoiceBatchCreate from 'components/resources/InvoiceBatch/Create';

import ContractBatchList from 'components/resources/ContractBatch/List';
import ContractBatchShow from 'components/resources/ContractBatch/Show';
import ContractBatchCreate from 'components/resources/ContractBatch/Create';

import PaymentBatchList from 'components/resources/PaymentBatch/List';
import PaymentBatchShow from 'components/resources/PaymentBatch/Show';
import PaymentBatchCreate from 'components/resources/PaymentBatch/Create';

import ContractorList from 'components/resources/Contractor/List';
import ContractorShow from 'components/resources/Contractor/Show';
import ContractorCreate from 'components/resources/Contractor/Create';
import ContractorEdit from 'components/resources/Contractor/Edit';

import { Alert } from '@mui/material';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new window.Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(getBookkeepingApiUrl(), httpClient);

const AdminContainer = () => {
  const authCtx = useAuthContext();

  const permissions = usePermissions();

  if (!permissions.length) {
    return (
      <Alert>Forbidden</Alert>
    );
  }

  const AvailableComponents = useMemo(() => [
    { component: TransactionList, permissions: ['view'], key: 'TransactionList' },
    { component: TransactionShow, permissions: ['view'], key: 'TransactionShow' },
    { component: DocumentList, permissions: ['view'], key: 'DocumentList' },
    { component: DocumentShow, permissions: ['view'], key: 'DocumentShow' },
    { component: DocumentEdit, permissions: ['view', 'edit'], key: 'DocumentEdit' },
    { component: MappingList, permissions: ['view'], key: 'MappingList' },
    { component: MappingShow, permissions: ['view'], key: 'MappingShow' },
    { component: MappingCreate, permissions: ['view', 'edit'], key: 'MappingCreate' },
    { component: MappingEdit, permissions: ['view', 'edit'], key: 'MappingEdit' },
    { component: DownloadList, permissions: ['view'], key: 'DownloadList' },
    { component: DownloadShow, permissions: ['view'], key: 'DownloadShow' },
    { component: DownloadCreate, permissions: ['view'], key: 'DownloadCreate' },

    { component: InvoiceBatchList, permissions: ['view', 'edit'], key: 'InvoiceBatchList' },
    { component: InvoiceBatchShow, permissions: ['view', 'edit'], key: 'InvoiceBatchShow' },
    { component: InvoiceBatchCreate, permissions: ['view', 'edit'], key: 'InvoiceBatchCreate' },
    { component: ContractBatchList, permissions: ['view', 'edit'], key: 'ContractBatchList' },
    { component: ContractBatchShow, permissions: ['view', 'edit'], key: 'ContractBatchShow' },
    { component: ContractBatchCreate, permissions: ['view', 'edit'], key: 'ContractBatchCreate' },
    { component: PaymentBatchList, permissions: ['view', 'edit'], key: 'PaymentBatchList' },
    { component: PaymentBatchShow, permissions: ['view', 'edit'], key: 'PaymentBatchShow' },
    { component: PaymentBatchCreate, permissions: ['view', 'edit'], key: 'PaymentBatchCreate' },

    { component: ContractorList, permissions: ['view', 'edit'], key: 'ContractorList' },
    { component: ContractorShow, permissions: ['view', 'edit'], key: 'ContractorShow' },
    { component: ContractorCreate, permissions: ['view', 'edit'], key: 'ContractorCreate' },
    { component: ContractorEdit, permissions: ['view', 'edit'], key: 'ContractorEdit' },
  ].reduce((acc, item) => {
    const allowed = item.permissions.every((permissionItem) => permissions.includes(permissionItem));
    if (!allowed) {
      return acc;
    }
    return {
      ...acc,
      [item.key]: item.component,
    };
  }, {}), [permissions]);

  return (
    <Admin
      basename="/app"
      authProvider={authCtx.raAuthProvider}
      dataProvider={dataProvider}
    >
      <Resource
        name="statement/transactions"
        list={AvailableComponents.TransactionList}
        show={AvailableComponents.TransactionShow}
      />
      <Resource
        name="documents"
        list={AvailableComponents.DocumentList}
        show={AvailableComponents.DocumentShow}
        edit={AvailableComponents.DocumentEdit}
      />
      <Resource
        name="mappings"
        list={AvailableComponents.MappingList}
        show={AvailableComponents.MappingShow}
        create={AvailableComponents.MappingCreate}
        edit={AvailableComponents.MappingEdit}
      />
      <Resource
        name="downloads"
        list={AvailableComponents.DownloadList}
        show={AvailableComponents.DownloadShow}
        create={AvailableComponents.DownloadCreate}
      />
      <Resource
        name="invoice-batches"
        list={AvailableComponents.InvoiceBatchList}
        show={AvailableComponents.InvoiceBatchShow}
        create={AvailableComponents.InvoiceBatchCreate}
      />
      <Resource
        name="contract-batches"
        list={AvailableComponents.ContractBatchList}
        show={AvailableComponents.ContractBatchShow}
        create={AvailableComponents.ContractBatchCreate}
      />
      <Resource
        name="payment-batches"
        list={AvailableComponents.PaymentBatchList}
        show={AvailableComponents.PaymentBatchShow}
        create={AvailableComponents.PaymentBatchCreate}
      />
      <Resource
        name="contractors"
        list={AvailableComponents.ContractorList}
        show={AvailableComponents.ContractorShow}
        create={AvailableComponents.ContractorCreate}
        edit={AvailableComponents.ContractorEdit}
      />
      <CustomRoutes>
        <Route path="/my-profile" element={<div>My Profile</div>} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminContainer;
