import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  TextInput,
  DateInput,
  SelectInput,
  SelectArrayInput,
  ShowButton,
  BulkExportButton,
  BooleanField,
  Link,
  useRecordContext,
  useCreatePath,
} from 'react-admin';
import { Stack } from '@mui/material';
import Receipt from '@mui/icons-material/Receipt';
import PostAdd from '@mui/icons-material/PostAdd';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import SyncButton from 'components/buttons/SyncTransactionButton';

import exporter from './exporter';

const CURRENCIES_OPTIONS = [
  { id: 'EUR', name: 'EUR' },
  { id: 'USD', name: 'USD' },
];

const TRANSACTION_TYPE_OPTIONS = [
  { id: 'CONVERSION', name: 'CONVERSION' },
  { id: 'CARD', name: 'CARD' },
  { id: 'TRANSFER', name: 'TRANSFER' },
  { id: 'DEPOSIT', name: 'DEPOSIT' },
  { id: 'MONEY_ADDED', name: 'MONEY_ADDED' },
];

const MAPPED_CHOICES = [
  { id: 'MAPPED', name: 'Mapped' },
  { id: 'UNMAPPED', name: 'NOT Mapped' },
  { id: 'ANY', name: 'Any' },
];

const ButtonToolbar = () => (
  <Stack direction="row" flexGrow={1} justifyContent="end">
    <SyncButton />,
  </Stack>
);

const postFilters = [
  <TextInput label="Search" source="q" alwaysOn resettable />,
  <DateInput label="Date from" source="dateFrom" alwaysOn />,
  <DateInput label="Date to" source="dateTo" alwaysOn />,
  <ButtonToolbar source="_toolbar" alwaysOn />,
  <SelectInput label="Balance currency" source="data.amount.currency" choices={CURRENCIES_OPTIONS} />,
  <SelectInput
    label="Target currency"
    source="data.exchangeDetails.toAmount.currency"
    choices={CURRENCIES_OPTIONS}
  />,
  <SelectArrayInput label="Transaction type" source="transactionType" choices={TRANSACTION_TYPE_OPTIONS} />,
  <SelectInput label="Mapped" source="mappedStatus" choices={MAPPED_CHOICES} resettable />,
];

const transactionRowStyle = (record) => {
  let backgroundColor = 'transparent';
  switch (true) {
    case record?.data?.details?.type === 'CONVERSION': break;
    case record?.data?.amount?.value > 0: {
      backgroundColor = '#eeffee';
      break;
    }
    case record?.data?.amount?.value < 0: {
      backgroundColor = '#ffeeee';
      break;
    }
    default:
  }
  return { backgroundColor };
};

const MappingField = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  const showLink = createPath({ resource: 'mappings', type: 'show', id: record?._mapping?._id });
  const createLink = createPath({ resource: 'mappings', type: 'create' });
  const createFullLink = `${createLink}?transaction=${record?.id}&amount=${record?._transactionTotal}`;
  return (
    <>
      <If condition={!!record?._mapping}>
        <Link to={showLink}>
          <Receipt />
        </Link>
      </If>
      <If condition={!record?._mapping}>
        <Link to={createFullLink}>
          <PostAdd />
        </Link>
      </If>
    </>
  );
};

const TransactionList = () => (
  <List exporter={exporter} filters={postFilters}>
    <Datagrid
      rowStyle={transactionRowStyle}
      bulkActionButtons={<BulkExportButton exporter={exporter} />}
    >
      <style>
        {`
           [data-source=_toolbar] {
              flex-grow: 1;
              justify-content: end;
           }
        `}
      </style>
      <DateFieldUnixtime source="timestamp" label="Date" showTime />
      <TextField source="data.details.description" label="Description" />
      <TextField source="data.details.paymentReference" label="Reference" />
      <MappingField label="Mapping" />
      <BooleanField source="_mapped" label="Mapped" />
      <NumberField source="data.amount.value" label="Charged Amount" />
      <TextField source="data.amount.currency" label="Balance Currency" />
      <NumberField source="data.totalFees.value" label="Charged Fee" />
      <TextField source="data.totalFees.currency" label="Fee Currency" />
      <NumberField source="data.exchangeDetails.toAmount.value" label="Payment Amount" />
      <TextField source="data.exchangeDetails.toAmount.currency" label="Payment Currency" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default TransactionList;
