import React, { useRef } from 'react';
import {
  NumberField,
  TextInput,
  DateInput,
  SelectInput,
  ShowButton,
  Edit,
  NumberInput,
  TabbedForm,
  FormTab,
  Labeled,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin';

import {
  Divider,
  Stack,
} from '@mui/material';
import _ from 'lodash';

import SuggestInput from 'components/inputs/SuggestInput';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime';
import ParseButton from 'components/buttons/ParseButton';
import PdfPreview from 'components/common/PdfPreview';
import Refresher from 'components/common/Refresher';
import { SuggestionProvider } from 'providers/SuggestionProvider';

import LabeledSpaceWrapper from 'components/common/LabeledSpaceWrapper/LabeledSpaceWrapper';

import SuggestionToggleButton from './SuggestionToggleButton';
import InvoiceItemAutofillButton from './InvoiceItemAutofillButton';
import SyncTotalButton from './SyncTotalButton';
import MagicFillButton from './MagicFillButton';

import {
  dateFormatter,
  dateParseAndFormat,
  dateParsers,
  numericParsers,
  singleValueJoiner,
} from './parsers';

const DOCUMENT_TYPES = [
  { id: 'CONTRACT', name: 'Contract' },
  { id: 'INVOICE', name: 'Invoice' },
  { id: 'RECEIPT', name: 'Receipt' },
  { id: 'OTHER', name: 'Other' },
];

const INVOICE_TYPES = [
  { id: 'IN', name: 'In' },
  { id: 'OUT', name: 'Out' },
];

const TEMPLATE_OPTIONS = [
  { id: 'ON', name: 'Yes' },
  { id: 'OFF', name: 'No' },
];

const validateDocumentEdit = (values) => {
  const errors = {};
  const total = values?.document?.total || 0;
  const subtotals = values?.document?.invoiceItems.map(({ price, quantity }) => (price * quantity) || 0);
  const sum = subtotals.reduce((a, b) => a + b, 0);
  if (!Number.isFinite(total) || !Number.isFinite(sum) || Math.abs(total - sum) > 0.01) {
    errors['document.total'] = 'Total is wrong';
  }
  return errors;
};

const DocumentEdit = () => {
  const refresher0Ref = useRef();
  const refresher1Ref = useRef();
  const handleRefresh = () => {
    refresher0Ref.current?.refresh();
    refresher1Ref.current?.refresh();
  };

  return (
    <Edit
      className="DocumentEdit"
      actions={<ShowButton />}
      transform={(data) => {
        _.set(data, 'document.issueDate', new Date(_.get(data, 'document.issueDate')).getTime());
        _.set(data, 'document.dueDate', new Date(_.get(data, 'document.dueDate')).getTime());
        return { ...data };
      }}
    >
      <SuggestionProvider>
        <style>
          {`
              .PdfPreview {
                max-height: calc(100vh - 196px);
                min-height: calc(100vh - 196px);
              }
              .DocumentEditForm {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
              }

              div.DocumentEditForm {
                height: 100%;
              }

              form.DocumentEditForm {
                width: calc(100% - 500px);
                max-height: calc(100vh - 144px);
                position: relative;
              }

              .DocumentEditForm > .MuiCardContent-root {
                flex-grow: 1;
              }

              .tabbed-form.DocumentEditForm > .MuiCardContent-root {
                overflow: auto;
              }

              .DocumentEdit.edit-page {
                text-align: right;
              }

              .DocumentEdit > .RaEdit-main, .DocumentEdit > .RaEdit-main * {
                text-align: left;
              }
          `}
        </style>
        <Stack direction="row" spacing={2} p={2}>
          <Stack direction="column" spacing={2}>
            <div>
              <SuggestionToggleButton />
              <ParseButton />
            </div>
            <PdfPreview />
          </Stack>
          <TabbedForm
            className="DocumentEditForm"
            validate={validateDocumentEdit}
            reValidateMode="onChange"
          >
            <FormTab label="Summary">
              <Stack direction="column" width="100%">
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" width="100%">
                  <LabeledSpaceWrapper label="Created at">
                    <DateFieldUnixtime
                      source="timestamp"
                      showTime
                    />
                  </LabeledSpaceWrapper>
                  <Stack direction="row" spacing={2}>
                    <TextInput source="file.type" label="Mime type" />
                    <SelectInput source="template" label="Use this document as template" choices={TEMPLATE_OPTIONS} />
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} width="100%">
                  <Stack direction="column" width="50%">
                    <SelectInput source="document.type" label="Document Type" choices={DOCUMENT_TYPES} />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.id"
                      label="Document ID"
                      mainInputProps={{ style: { flexGrow: 1 } }}
                    />
                    <p>Dates</p>
                    <SuggestInput
                      mainInput={DateInput}
                      source="document.issueDate"
                      label="Issue Date"
                      mainInputProps={{
                        style: { flexGrow: 1 },
                        ...dateParseAndFormat,
                      }}
                      parsers={dateParsers}
                      formatter={dateFormatter}
                    />
                    <p>Amount</p>
                    <SuggestInput
                      mainInput={NumberInput}
                      source="document.total"
                      label="Invoice Total"
                      parsers={numericParsers}
                      joiner={singleValueJoiner}
                      mainInputProps={{ style: { flexGrow: 1 } }}
                    />
                    <p>Description</p>
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.description"
                      label="Description"
                      mainInputProps={{ multiline: true }}
                      multi
                    />
                  </Stack>
                  <Stack direction="column" width="50%">
                    <SelectInput source="document.invoiceType" label="Invoice Type" choices={INVOICE_TYPES} />
                    <TextInput source="file.name" label="File Name" />
                    <p>&nbsp;</p>
                    <SuggestInput
                      mainInput={DateInput}
                      source="document.dueDate"
                      label="Due Date"
                      mainInputProps={{
                        style: { flexGrow: 1 },
                        ...dateParseAndFormat,
                      }}
                      parsers={dateParsers}
                      formatter={dateFormatter}
                    />
                    <p>&nbsp;</p>
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.currency"
                      label="Invoice Currency"
                      mainInputProps={{ style: { flexGrow: 1 } }}
                    />
                    <p>&nbsp;</p>
                    <TextInput source="document.servicesCode" label="Services Code" />
                  </Stack>
                </Stack>
              </Stack>
            </FormTab>
            <FormTab label="Receiver and Issuer">
              <Stack direction="column" spacing={2} width="100%">
                <Stack direction="row" spacing={2} width="100%">
                  <Stack direction="column" width="50%">
                    <p>Payment methods</p>
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerAccount"
                      label="Issuer Account"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerRouting"
                      label="Issuer Routing Number (For US / ACH)"
                    />
                    <TextInput
                      source="meta.issuerMerchant"
                      label="Issuer Merchant Name (FOR CARD PURCHASES)"
                    />
                  </Stack>
                  <Stack direction="column" width="50%">
                    <p>&nbsp;</p>
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerBIC"
                      label="Issuer BIC / SWIFT Code"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerPaymentLink"
                      label="Issuer Payment Link"
                    />
                    <TextInput
                      source="meta.senderName"
                      label="Recipient Sender Name (FOR DEPOSITS)"
                    />
                  </Stack>
                </Stack>
                <Divider flexItem />
                <Stack direction="row" spacing={2} width="100%">
                  <Stack direction="column" width="50%">
                    <p>Issuer</p>
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerName"
                      label="Issuer Name"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerCountry"
                      label="Issuer Country"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerVAT"
                      label="Issuer VAT"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.issuerAddress"
                      label="Issuer Address"
                      mainInputProps={{ multiline: true }}
                      multi
                    />
                  </Stack>
                  <Divider orientation="vertical" flexItem />
                  <Stack direction="column" width="50%">
                    <p>Receiver</p>
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.receiverName"
                      label="Receiver Name"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.receiverCountry"
                      label="Receiver Country"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.receiverVAT"
                      label="Receiver VAT"
                    />
                    <SuggestInput
                      mainInput={TextInput}
                      source="document.receiverAddress"
                      label="Receiver Address"
                      mainInputProps={{ multiline: true }}
                      multi
                    />
                  </Stack>
                </Stack>
              </Stack>
            </FormTab>
            <FormTab label="Invoice Items">
              <Refresher ref={refresher0Ref}>
                <ArrayInput
                  source="document.invoiceItems"
                  label="Invoice items"
                  style={{ paddingTop: '20px' }}
                >
                  <SimpleFormIterator inline>
                    <FormDataConsumer>
                      {({ scopedFormData, getSource }) => (
                        <Stack direction="row" spacing={2} p={1}>
                          <NumberInput source={getSource('price')} label="Price" style={{ margin: 0 }} />
                          <NumberInput source={getSource('quantity')} label="Quantity" />
                          <Labeled label="Subtotal">
                            <NumberField
                              record={{ subtotal: (scopedFormData.price * scopedFormData.quantity) || 0 }}
                              source="subtotal"
                            />
                          </Labeled>
                        </Stack>
                      )}
                    </FormDataConsumer>
                    <TextInput source="servicesCode" label="Services Code" style={{ width: '100%' }} />
                    <TextInput source="description" label="Description" multiline style={{ width: '100%' }} />
                  </SimpleFormIterator>
                </ArrayInput>
              </Refresher>
            </FormTab>
            <div>
              <MagicFillButton />
              <SyncTotalButton />
              <InvoiceItemAutofillButton onRefresh={handleRefresh} />
            </div>
          </TabbedForm>
        </Stack>
      </SuggestionProvider>
    </Edit>
  );
};

export default DocumentEdit;
