import React from 'react';

import { useShowContext } from 'react-admin';
import { IconButton } from '@mui/material';

import Download from '@mui/icons-material/Download';

import { getBookkeepingApiUrl } from 'utils/api';

const CSVButton = ({ prefix }) => {
  const { record } = useShowContext();
  const handleGetCsv = async () => {
    window.location.href = (
      `${getBookkeepingApiUrl()}/${prefix}/${record?.id}/csv?token=${localStorage.getItem('token')}`
    );
  };
  return (
    <IconButton onClick={handleGetCsv}>
      <Download />
    </IconButton>
  );
};

export default CSVButton;
