import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AutoFixNormal from '@mui/icons-material/AutoFixNormal';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useController, useFieldArray } from 'react-hook-form';

const InvoiceItemAutofillButton = ({ onRefresh }) => {
  const controllerItems = useFieldArray({ name: 'document.invoiceItems' });
  const controllerDescription = useController({ name: 'document.description' });
  const controllerTotal = useController({ name: 'document.total' });
  const controllerServicesCode = useController({ name: 'document.servicesCode' });

  const handleAutofill = () => {
    const { field: { value: total } } = controllerTotal;
    const { field: { value: servicesCode } } = controllerServicesCode;
    const { field: { value: description } } = controllerDescription;
    controllerItems.replace([
      {
        description,
        subtotal: total,
        price: total,
        quantity: 1,
        servicesCode,
      },
    ]);
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <Button onClick={handleAutofill}><><AutoFixNormal />AUTOFILL ITEMS</></Button>
  );
};

InvoiceItemAutofillButton.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

export default InvoiceItemAutofillButton;
