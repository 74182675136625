import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

const Refresher = forwardRef((props, ref) => {
  const [hidden, setHidden] = useState(false);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      setHidden(true);
    },
  }), []);

  useEffect(() => {
    if (hidden) {
      setHidden(false);
    }
  }, [hidden]);

  return (
    <If condition={!hidden}>
      {props.children}
    </If>
  );
});

Refresher.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Refresher;
