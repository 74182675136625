import React from 'react';

import {
  Datagrid,
  TextField,
  DateField,
  NumberField,
  SimpleShowLayout,
  Show,
  ArrayField,
  ChipField,
  ReferenceField,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Stack,
} from '@mui/material';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime';
import PdfPreview from 'components/common/PdfPreview';

const DocumentShow = () => (
  <Show className="DocumentShow">
    <style>
      {`
          .PdfPreview {
            max-height: calc(100vh - 320px);
            min-height: calc(100vh - 320px);
          }
      `}
    </style>
    <Stack direction="column" spacing={2} p={2}>
      <Card raised style={{ padding: '10px' }}>
        <CardHeader title="Document" />
        <Stack direction="row">
          <SimpleShowLayout>
            <DateField source="document.issueDate" label="Issue Date" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="document.id" label="Document ID" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="file.name" label="File name" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestamp" label="Created at" showTime />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2}>
        <PdfPreview />
        <Stack direction="column" flexGrow={1} spacing={4}>
          <Stack direction="row" spacing={2}>
            <SimpleShowLayout>
              <h3>Invoice Data</h3>
              <TextField source="document.id" label="Invoice ID" />
              <ChipField source="document.invoiceType" label="Invoice Type" />
              <DateField source="document.issueDate" label="Issue Date" />
              <DateField source="document.dueDate" label="Due Date" />
              <TextField source="document.description" label="Description" />
              <TextField source="document.servicesCode" label="Services Code" />
              <NumberField source="document.total" label="Invoice Total" />
              <NumberField source="_mappedTotal" label="Mapped Total" />
              <TextField source="document.currency" label="Invoice Currency" />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <h3>Issuer Data</h3>
              <TextField source="document.issuerName" label="Issuer Name" />
              <TextField source="document.issuerCountry" label="Issuer Country" />
              <TextField source="document.issuerAddress" label="Issuer Address" />
              <TextField source="document.issuerVAT" label="Issuer VAT" />
              <TextField source="document.issuerAccount" label="Issuer Account" />
              <TextField source="document.issuerBIC" label="Issuer BIC" />
              <TextField source="document.issuerRouting" label="Issuer Routing Number" />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <h3>Receiver Data</h3>
              <TextField source="document.receiverName" label="Receiver Name" />
              <TextField source="document.receiverCountry" label="Receiver Country" />
              <TextField source="document.receiverAddress" label="Receiver Address" />
              <TextField source="document.receiverVAT" label="Receiver VAT" />
            </SimpleShowLayout>
          </Stack>
          <Stack direction="column">
            <h3>Invoice Items</h3>
            <SimpleShowLayout>
              <ArrayField source="document.invoiceItems" label="Invoice items">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="description" label="Description" />
                  <TextField source="servicesCode" label="Services Code" />
                  <NumberField source="price" label="Price" />
                  <NumberField source="quantity" label="Quantity" />
                  <NumberField source="subtotal" label="Subtotal" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Stack>
          <Stack direction="column">
            <h3>Mapped transactions</h3>
            <SimpleShowLayout>
              <ArrayField source="_mappedTransactions" label="Mapped transactions">
                <Datagrid bulkActionButtons={false}>
                  <NumberField source="amount" label="Amount" />
                  <ReferenceField
                    source="transactionId"
                    label="Transaction"
                    reference="statement/transactions"
                    link="show"
                  >
                    <TextField source="data.details.description" />
                  </ReferenceField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default DocumentShow;
