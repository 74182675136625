import React from 'react';
import moment from 'moment';
import {
  Create,
  SelectInput,
  NumberInput,
  SimpleForm,
} from 'react-admin';

const DOWNLOAD_TYPES = [
  { name: 'Document', id: 'document' },
];

const MONTH_TYPES = [
  { name: 'January', id: 0 },
  { name: 'February', id: 1 },
  { name: 'March', id: 2 },
  { name: 'April', id: 3 },
  { name: 'May', id: 4 },
  { name: 'June', id: 5 },
  { name: 'July', id: 6 },
  { name: 'August', id: 7 },
  { name: 'September', id: 8 },
  { name: 'October', id: 9 },
  { name: 'November', id: 10 },
  { name: 'December', id: 11 },
];

const defaultValues = {
  year: moment().subtract(1, 'month').year(),
  month: moment().subtract(1, 'month').month(),
};

const PaymentBatchCreate = () => (
  <Create>
    <SimpleForm defaultValues={defaultValues}>
      <SelectInput source="type" choices={DOWNLOAD_TYPES} />
      <SelectInput source="month" choices={MONTH_TYPES} />
      <NumberInput source="year" />
    </SimpleForm>
  </Create>
);

export default PaymentBatchCreate;
