import React, { useMemo, useState } from 'react';

import {
  useShowContext,
  useEditContext,
} from 'react-admin';

import { Document, Page, pdfjs } from 'react-pdf';

import {
  Card,
  CardHeader,
  Stack,
} from '@mui/material';
import { getBookkeepingApiUrl, TOKEN_KEY } from 'utils/api';
import DownloadIcon from '@mui/icons-material/Download';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const isPdf = (fileType) => fileType === 'application/pdf';

const PdfPreview = () => {
  const { record: showRecord } = useShowContext();
  const { record: editRecord } = useEditContext();
  const record = showRecord || editRecord;

  const [pageCount, setPageCount] = useState(0);

  const pages = useMemo(() => Array.from({ length: pageCount }), [pageCount]);

  const url = useMemo(() => (
    `${getBookkeepingApiUrl()}/documents/${record?.id}/file`
  ), [record?.id]);

  const handleLoad = (event) => {
    setPageCount(event?._pdfInfo?.numPages || 0);
  };

  const options = useMemo(() => ({
    url,
    httpHeaders: { authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}` },
  }), [url]);

  if (!isPdf(record?.file?.type)) {
    return <Card className="PdfPreview" raised><CardHeader title="No pdf found" /></Card>;
  }

  return (
    <Card raised style={{ height: '100%', width: '450px', overflow: 'auto', flexShrink: 0 }} className="PdfPreview">
      <CardHeader
        title={(
          <Stack direction="row" spacing={4} alignItems="center">
            <p>Content</p>
            <a
              href={
                `${getBookkeepingApiUrl()}/documents/${record.id}/file?token=${localStorage.getItem(TOKEN_KEY)}`
              }
              style={{ fontSize: 14 }}
            >
              <DownloadIcon />
            </a>
          </Stack>
        )}
      />
      <Document file={options} onLoadSuccess={handleLoad}>
        {pages.map((page, index) => (
          <Page pageNumber={index + 1} key={index} />
        ))}
      </Document>
    </Card>
  );
};

export default PdfPreview;
