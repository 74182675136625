import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useEditContext } from 'react-admin';
import { getParsedDocument } from 'utils/api';
import EventEmitter from 'events';

const metrics = (text) => {
  let points = 0;
  const alphaNumericSymbols = text.replace(/[^a-z0-9]/gi, '');
  if (/docusign/gi.test(alphaNumericSymbols)) {
    return 0;
  }
  if (/ \/ $/gi.test(text)) {
    points -= 5;
  }
  if (/ \/ /gi.test(text)) {
    points -= 3;
  }
  if (/^SO-/g.test(text)) {
    points += 20;
  }
  return alphaNumericSymbols.length + points;
};

const SuggestionContext = createContext();

export const SuggestionProvider = ({ children }) => {
  const { record } = useEditContext();
  const id = record?.id;
  const [autofillValues, setAutofillValues] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsOpen, setSuggestionsOpen] = useState(true);
  const [ee] = useState(() => new EventEmitter());

  const handleSuggestionsLoad = async (documentId) => {
    const parsedDocument = await getParsedDocument(documentId);
    parsedDocument.sort((item0, item1) => metrics(item1.text) - metrics(item0.text));
    const newSuggestions = parsedDocument.map(({ id: suggestionId, text }) => ({
      id: suggestionId, name: text, metrics: metrics(text),
    }));
    setSuggestions(newSuggestions);
  };

  const handleAutofillSubscription = useCallback((callback) => {
    ee.on('update', callback);
    return () => ee.off('update', callback);
  }, []);

  const handleAutofill = useCallback((newValues) => {
    setAutofillValues(newValues);
  }, []);

  const handleSuggestionsToggle = () => setSuggestionsOpen((prev) => !prev);

  useEffect(() => {
    handleSuggestionsLoad(id).then();
  }, [id]);

  useEffect(() => {
    if (autofillValues) {
      ee.emit('update', autofillValues);
    }
  }, [autofillValues]);

  const ctx = useMemo(() => ({
    suggestions,
    open: suggestionsOpen,
    toggle: handleSuggestionsToggle,
    load: handleSuggestionsLoad,
    onAutofill: handleAutofillSubscription,
    autofill: handleAutofill,
  }), [
    suggestions,
    suggestionsOpen,
    handleSuggestionsToggle,
    handleSuggestionsLoad,
  ]);

  return (
    <SuggestionContext.Provider value={ctx}>
      {children}
    </SuggestionContext.Provider>
  );
};

SuggestionProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useSuggestions = () => useContext(SuggestionContext);
