import EditCreateForm from 'components/resources/Contractor/EditCreateForm';
import React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';

const defaultValues = {};

const ContractorCreate = () => (
  <Create>
    <SimpleForm defaultValues={defaultValues}>
      <EditCreateForm />
    </SimpleForm>
  </Create>
);

export default ContractorCreate;
