import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Edit,
  SelectInput,
  NumberInput,
  SimpleForm,
  BooleanInput,
  TextInput,
} from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';

const defaultValues = {};

const SERVICE_CHOICES = [
  { name: 'Type A', id: 'TYPE A' },
  { name: 'Type B', id: 'TYPE B' },
  { name: 'Type C', id: 'TYPE C' },
  { name: 'Type D', id: 'TYPE D' },
  { name: 'Type E', id: 'TYPE E' },
  { name: 'Other', id: 'OTHER' },
];

const PRICE_CHOICES = [
  { name: 'Hourly', id: 'H-TMP' },
  { name: 'Fixed', id: 'F-TMP' },
  { name: 'Other', id: 'OTHER' },
];

const PAYMENT_TYPE_CHOICES = [
  { name: 'Prepayment', id: 'PREPAYMENT' },
  { name: 'Postpayment', id: 'POSTPAYMENT' },
];

const EditCreateForm = () => {
  const { watch, setValue } = useFormContext();
  const values = watch();

  useEffect(() => {
    if (values?.billingSameAsRegistered) {
      if (values.billingAddress !== values.registeredAddress) {
        setValue('billingAddress', values.registeredAddress);
      }
    }
  }, [values?.billingSameAsRegistered]);

  return (
    <Stack>
      <TextInput source="label" />
      <TextInput source="email" />
      <TextInput source="legalName" />
      <TextInput source="defaultContractID" />
      <TextInput source="registeredAddress.line1" />
      <TextInput source="registeredAddress.line2" />
      <TextInput source="registeredAddress.city" />
      <TextInput source="registeredAddress.state" />
      <TextInput source="registeredAddress.country" />
      <TextInput source="registeredAddress.zipCode" />
      <TextInput source="accountNumber" />
      <TextInput source="swiftCode" />
      <TextInput source="taxID" />
      <TextInput source="registerID" />
      <SelectInput source="serviceType" choices={SERVICE_CHOICES} />
      <SelectInput source="legalPriceType" choices={PRICE_CHOICES} />
      <BooleanInput source="billingSameAsRegistered" />
      <If condition={!values?.billingSameAsRegistered}>
        <TextInput source="billingAddress.line1" />
        <TextInput source="billingAddress.line2" />
        <TextInput source="billingAddress.city" />
        <TextInput source="billingAddress.state" />
        <TextInput source="billingAddress.country" />
        <TextInput source="billingAddress.zipCode" />
      </If>
      <NumberInput source="salaryHourly" />
      <NumberInput source="salaryMonthly" />
      <NumberInput source="taxPercent" />
      <NumberInput source="taxFixed" />
      <SelectInput source="defaultPaymentType" choices={PAYMENT_TYPE_CHOICES} />
    </Stack>
  );
};

export default EditCreateForm;
