import React, { useState, useEffect } from 'react';

import { useRefresh } from 'react-admin';
import { IconButton } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import CachedIcon from '@mui/icons-material/Cached';

import { syncTransactions } from 'utils/api';

const useStyles = makeStyles(() => (
  createStyles({
    rotateIcon: {
      animation: '$spin 2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  })
));

const SyncTransactionButton = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const handleSync = async () => {
    setLoading(true);
    await syncTransactions();
    refresh();
  };
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 10 * 1000);
      return timer;
    }
    return null;
  }, [loading]);
  return (
    <IconButton
      className={loading ? classes.rotateIcon : null}
      onClick={!loading ? handleSync : () => {}}
      disabled={loading}
    >
      <CachedIcon />
    </IconButton>
  );
};

export default SyncTransactionButton;
