import React from 'react';
import {

  Show, SimpleShowLayout, TextField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const MappingShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="status" label="Status" />
      <TextField source="type" label="Type" />
      <TextField source="fileType" label="Type" />
      <TextField source="meta.year" label="Year" />
      <TextField source="meta.month" label="Month" />
      <TextField source="createdBy.name" label="Created By" />
    </SimpleShowLayout>
  </Show>
);

export default MappingShow;
