import React from 'react';
import PropTypes from 'prop-types';
import { Labeled } from 'react-admin';

const LabeledSpaceWrapper = ({ children, label }) => (
  <div>
    <Labeled label={label}>
      {children}
    </Labeled>
    <p>&nbsp;</p>
  </div>
);

LabeledSpaceWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default LabeledSpaceWrapper;
