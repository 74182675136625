/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  AutocompleteInput,
  AutocompleteArrayInput,
} from 'react-admin';

import { Stack } from '@mui/material';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useController } from 'react-hook-form';
import { useSuggestions } from 'providers/SuggestionProvider';

const defaultValidator = (val) => val === null || val === undefined || Number.isNaN(val);

const defaultFormatter = (value) => value;

const defaultJoiner = (values) => values.join(' ');

const SuggestInput = ({
  mainInput,
  source,
  mainInputProps,
  suggestInputProps,
  parsers,
  label,
  multi,
  formatter,
  joiner,
  validator,
}) => {
  const { suggestions, open, onAutofill } = useSuggestions();
  const controllerMain = useController({ name: source });
  const controllerSuggest = useController({ name: `parsed.${source}` });

  const options = useMemo(() => {
    const newOptions = [{
      value: '',
      name: 'Static value',
      id: 'static',
    }];
    for (const suggestion of suggestions) {
      if (parsers) {
        for (const parser of parsers) {
          let value = null;
          try {
            value = parser.parse(suggestion.name);
          } catch (e) {
            value = null;
          }
          if (validator(value)) {
            // eslint-disable-next-line no-continue
            continue;
          }
          let formattedValue = value;
          try {
            formattedValue = formatter(value);
          } catch (e) {
            formattedValue = value;
          }
          newOptions.push({
            value,
            name: `${formattedValue} (parsed from "${suggestion.name}")`,
            id: `${suggestion.id}+${parser.id}`,
          });
        }
      } else {
        newOptions.push({
          value: suggestion.name,
          name: suggestion.name,
          id: suggestion.id,
        });
      }
    }
    return newOptions;
  }, [suggestions, parsers]);

  const handleChange = useCallback((newValue) => {
    const optionIds = !Array.isArray(newValue) ? ((newValue && [newValue]) || []) : newValue;
    const optionsSelected = optionIds.map((optionItemId) => options.find(({ id }) => (
      id === optionItemId
    ))).filter((item) => !!item);

    if (optionsSelected?.length) {
      const selectedValues = optionsSelected.map(({ value }) => value);
      const joinedValues = joiner(selectedValues);
      controllerMain.field.onChange(joinedValues);
    }
  }, [options]);

  useEffect(() => {
    const unsubscribe = onAutofill((extractedValues) => {
      const value = _.get(extractedValues, source);
      controllerSuggest.field.onChange(value);
      handleChange(value);
    });
    return () => unsubscribe();
  }, [handleChange]);

  const { style: mainStyle, ...other } = mainInputProps || {};
  const patchedMainStyle = { flexGrow: 1, ...mainStyle };
  const patchedMainInputProps = { style: patchedMainStyle, ...other };

  const Input = mainInput;
  return (
    <Stack spacing={2} direction={multi ? 'column' : 'row'}>
      <Input {...patchedMainInputProps} source={source} label={label} />
      <If condition={open}>
        <If condition={!multi}>
          <AutocompleteInput
            label={`Parsed ${label}`}
            {...suggestInputProps}
            source={`parsed.${source}`}
            choices={options}
            onChange={handleChange}
          />
        </If>
        <If condition={multi}>
          <AutocompleteArrayInput
            label={`Parsed ${label}`}
            {...suggestInputProps}
            source={`parsed.${source}`}
            choices={options}
            onChange={handleChange}
            format={(value) => ((value && Array.isArray(value)) ? value : [value])}
            sx={{ width: '100%' }}
          />
        </If>
      </If>
    </Stack>
  );
};

SuggestInput.defaultProps = {
  mainInputProps: {},
  suggestInputProps: {},
  label: undefined,
  multi: false,
  parsers: null,
  formatter: defaultFormatter,
  joiner: defaultJoiner,
  validator: defaultValidator,
};

SuggestInput.propTypes = {
  mainInput: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  source: PropTypes.string.isRequired,
  mainInputProps: PropTypes.object,
  suggestInputProps: PropTypes.object,
  parsers: PropTypes.arrayOf(PropTypes.func),
  label: PropTypes.string,
  multi: PropTypes.bool,
  formatter: PropTypes.func,
  joiner: PropTypes.func,
  validator: PropTypes.func,
};

export default SuggestInput;
