import React from 'react';

import { useSuggestions } from 'providers/SuggestionProvider';
import SpeakerNotes from '@mui/icons-material/SpeakerNotes';
import { Button } from 'react-admin';

const SuggestionToggleButton = () => {
  const { open, toggle } = useSuggestions();

  return (
    <Button onClick={toggle}>
      <>
        <SpeakerNotes />
        {open ? 'HIDE' : 'SHOW'} SUGGESIONS
      </>
    </Button>
  );
};

export default SuggestionToggleButton;
