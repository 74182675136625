import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  FunctionField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { getBookkeepingApiUrl, TOKEN_KEY } from 'utils/api';

const InvoiceBatchList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <TextField source="title" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default InvoiceBatchList;
