import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Create,
  SelectInput,
  NumberInput,
  SimpleForm,
  ArrayInput,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
  AutocompleteInput,
  DateInput,
  usePrevious,
  useGetList,
} from 'react-admin';
import { Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';
import { clone, cloneDeep } from 'lodash';

const DOWNLOAD_TYPES = [
  { name: 'Document', id: 'document' },
];

const PREFILLER_EMAIL_CHOICES = [
  { name: 'MYKHAILO SHEREMET', id: 'mykhailo.sheremet@solidone.net' },
  { name: 'SHARED PREFILLER', id: 'docusign-prefill@solidone.net' },
];

const defaultValues = { title: `Invoice batch ${moment().format('YYYY-MM-DD')}` };

const InvoiceBatchCreateForm = () => {
  const { watch, setValue } = useFormContext();
  const { data: contractorsAll } = useGetList(
    'contractors',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'published_at', order: 'DESC' }
    },
  );

  const values = watch();
  const {
    contractors: contractorsValue,
    defaultIssueDate,
    defaultExchangeRateDate,
    defaultExchangeRate,
  } = values || {};
  const contractorsValueClone = cloneDeep(contractorsValue);
  const prevContractorsValue = usePrevious(contractorsValueClone);

  useEffect(() => {
    if (contractorsValue?.length !== prevContractorsValue?.length) {
      return;
    }
    const contractorsCount = contractorsValue?.length || 0;
    for (let i = 0; i < contractorsCount; i++) {
      const currentContractor = contractorsValue?.[i];
      const prevContractor = prevContractorsValue?.[i];
      if (currentContractor?.contractorID !== prevContractor?.contractorID) {
        const contractorValue = contractorsAll.find((value) => value?.id === currentContractor?.contractorID);
        const defaultInvoiceID = (contractorValue?.defaultContractID && defaultIssueDate) && (
          `${contractorValue?.defaultContractID}/${defaultIssueDate}/1`
        );
        const autoFilledContactor = {
          ...currentContractor,
          invoiceID: defaultInvoiceID,
          exchangeRateDate: defaultExchangeRateDate,
          exchangeRate: defaultExchangeRate,
          issueDate: defaultIssueDate,
        };
        setValue(`contractors.${i}`, autoFilledContactor);
      }
    }
  }, [prevContractorsValue]);

  return (
    <Stack>
      <TextInput source="title" isRequired />
      <SelectInput source="prefillerEmail" isRequired choices={PREFILLER_EMAIL_CHOICES} />
      <DateInput source="defaultExchangeRateDate" isRequired />
      <NumberInput source="defaultExchangeRate" isRequired />
      <DateInput source="defaultIssueDate" isRequired />
      <ArrayInput source="contractors">
        <SimpleFormIterator>
          <ReferenceInput source="contractorID" reference="contractors" isRequired>
            <AutocompleteInput optionText={(option) => option.legalName} />
          </ReferenceInput>
          <DateInput source="exchangeRateDate" isRequired />
          <NumberInput source="exchangeRate" isRequired />
          <DateInput source="issueDate" isRequired />
          <TextInput source="invoiceID" isRequired />
          <NumberInput source="invoiceSubtotal" isRequired />
          <NumberInput source="invoiceHours" isRequired />
        </SimpleFormIterator>
      </ArrayInput>
    </Stack>
  );
};

const InvoiceBatchCreate = () => (
  <Create>
    <SimpleForm defaultValues={defaultValues}>
      <InvoiceBatchCreateForm />
    </SimpleForm>
  </Create>
);

export default InvoiceBatchCreate;
