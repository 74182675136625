import React from 'react';
import { Button } from '@mui/material';
import AutoFixNormal from '@mui/icons-material/AutoFixNormal';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useController } from 'react-hook-form';

const SyncTotalButton = () => {
  const controllerItems = useController({ name: 'document.invoiceItems' });
  const controllerTotal = useController({ name: 'document.total' });

  const handleSync = () => {
    const { field } = controllerItems;
    const newTotal = (field?.value || [])
      .map(({ price, quantity }) => price * quantity)
      .reduce((a, b) => a + b, 0);
    controllerTotal.field.onChange(newTotal);
  };

  return (
    <Button onClick={handleSync}>
      <>
        <AutoFixNormal />SYNC TOTAL
      </>
    </Button>
  );
};

export default SyncTotalButton;
