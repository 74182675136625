import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';

const exporter = (transactions) => {
  jsonExport(transactions, {
    headers: [
      'id',
      'timestamp',
      'data.details.description',
      'data.amount.value',
      'data.amount.currency',
      'data.totalFees.value',
      'data.totalFees.currency',
      'data.exchangeDetails.toAmount.value',
      'data.exchangeDetails.toAmount.currency',
    ],
  }, (err, csv) => {
    downloadCSV(csv, 'transactions');
  });
};

export default exporter;
