import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  BooleanField,
  ChipField,
  NumberField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const ContractorList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <TextField source="label" />
      <TextField source="email" />
      <TextField source="legalName" />
      <TextField source="defaultContractID" />
      <ChipField source="serviceType" />
      <ChipField source="legalPriceType" />
      <NumberField source="salaryHourly" />
      <NumberField source="salaryMonthly" />
      <NumberField source="taxPercent" />
      <NumberField source="taxFixed" />
      <ChipField source="defaultPaymentType" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default ContractorList;
