import React, { useEffect, useMemo, useState } from 'react';

import {
  useEditContext,
} from 'react-admin';
import moment from 'moment';

import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import Refresh from '@mui/icons-material/Refresh';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { getDocumentTemplates, extractDocumentData, getDocument } from 'utils/api';
import { Spin } from 'antd';
import _, { get } from 'lodash';
import { useSuggestions } from 'providers/SuggestionProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';

const aggregateData = (extractedValues) => extractedValues.reduce((acc, extractedItem) => {
  _.set(acc, extractedItem.documentKey, extractedItem.isArray ? _.uniq(extractedItem.ids) : extractedItem.ids);
  return acc;
}, {});

const timestampToDDMMYYYY = (timestamp) => {
  const val = moment(timestamp);
  return val.isValid() ? val.format('DD/MM/YYYY') : null;
};

const MagicFillButton = () => {
  const { setValue, getValues } = useFormContext();
  const { autofill, suggestions } = useSuggestions();
  const { record } = useEditContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateId, setTemplateId] = useState([]);
  const documentId = record?.id;

  const templateOptions = useMemo(() => templates.map((template) => ({
    id: template?._id,
    name: [
      template?.document?.issuerName,
      template?.document?.id,
      timestampToDDMMYYYY(template?.document?.issueDate),
    ].filter((item) => !!item).join(' | '),
  })), [templates]);

  const handleLoadTemplates = async () => {
    try {
      const newTemplates = await getDocumentTemplates();
      setTemplates(newTemplates);
    } catch (e) {
      setTemplates([]);
    }
  };

  const handleChangeTemplate = (event) => {
    setTemplateId(event?.target.value);
  };

  const setDefaultTemplate = () => {
    const wholeStrings = new Set(
      suggestions.map(({ name }) => name),
    );
    const tokens = new Set(
      [...wholeStrings].flatMap((string) => [string, ...string.split(/\W/)]),
    );
    const suggestedTemplate = templates.find((template) => {
      const issuerName = get(template, 'document.issuerName');
      const issuerContained = tokens.has(issuerName);
      const receiverName = get(template, 'document.receiverName');
      const receiverContained = tokens.has(receiverName);
      return issuerContained && receiverContained;
    });
    setTemplateId(suggestedTemplate?._id);
  };

  const handleAutofill = async () => {
    setDialogOpen(true);
    setDefaultTemplate();
  };

  const autoFillInvoiceItems = () => {
    const values = getValues();
    const description = get(values, 'document.description');
    const servicesCode = get(values, 'document.servicesCode');
    const total = get(values, 'document.total');
    setValue('document.invoiceItems', [
      {
        description,
        subtotal: total,
        price: total,
        quantity: 1,
        servicesCode,
      },
    ]);
  };

  const handleDialogAccept = async () => {
    try {
      setLoading(true);
      setError(null);
      const extractedValues = await extractDocumentData(documentId, templateId);
      const extractedDynamicValues = extractedValues.filter((extractedValue) => !extractedValue.isStatic);
      const extractedStaticValues = extractedValues.filter((extractedValue) => extractedValue.isStatic);
      const extractedData = aggregateData(extractedDynamicValues);
      autofill(extractedData);
      extractedStaticValues.forEach((staticValue) => {
        setValue(staticValue.documentKey, staticValue.text);
      });
      setTimeout(autoFillInvoiceItems, 100);
      setDialogOpen(false);
    } catch (e) {
      const msg = (e?.message || e).toString();
      setError(msg);
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogReject = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    handleLoadTemplates();
  }, []);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogReject}
        maxWidth="lg"
      >
        <DialogTitle>Autofill</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              You are about to autofill document using automated extraction from PDF.
            </DialogContentText>
            <Stack direction="row" spacing={2}>
              <Select
                style={{ flexGrow: 1 }}
                options={templateOptions}
                onChange={handleChangeTemplate}
                value={templateId}
              >
                {templateOptions.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
              <Button onClick={handleLoadTemplates}><Refresh />REFRESH TEMPLATES LIST</Button>
            </Stack>
            {error && <Alert>{error}</Alert>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogReject}>Cancel</Button>
          <Button onClick={!loading && handleDialogAccept} autoFocus>
            <>
              <If condition={loading}>
                <Spin />
              </If>
              <If condition={!loading}>
                AUTOFILL NOW!
              </If>
            </>
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleAutofill}><><AutoFixHigh />MAGIC FILL</></Button>
    </>
  );
};

export default MagicFillButton;
