import React from 'react';
import moment from 'moment';
import {
  TextField,
  ChipField,
  ReferenceField,
  SingleFieldList,
  ArrayField,
  BooleanField,
  SimpleShowLayout,
  Show,
  Datagrid,
  NumberField,
  FunctionField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const timestampToDDMMYYYY = (timestamp) => {
  const val = moment(timestamp);
  return val.isValid() ? val.format('DD/MM/YYYY') : null;
};

const MappingShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="note" label="Note" />
      <ChipField source="mappingType" label="Mapping type" />
      <ReferenceField
        source="transactionId"
        reference="statement/transactions"
        link="show"
      >
        <TextField source="data.details.description" />
      </ReferenceField>
      <ArrayField label="Tags" source="tags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <BooleanField label="Confirmed" source="confirmed" />
      <BooleanField label="Locked" source="locked" />
      <ChipField label="Review status" source="reviewStatus" />
      <ArrayField label="Mapping Items" source="items">
        <Datagrid bulkActionButtons={null}>
          <NumberField source="amount" />
          <ReferenceField
            source="documentId"
            reference="documents"
            link="show"
          >
            <FunctionField
              render={(record) => [
                record?.document?.issuerName,
                record?.document?.id,
                timestampToDDMMYYYY(record?.document?.issueDate),
              ].join(' | ')}
            />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default MappingShow;
