import React from 'react';
import {

  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  DeleteButton,
  NumberField,
  Show, SimpleShowLayout, TextField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Stack } from '@mui/material';
import CSVButton from 'components/buttons/CSVButton';

const InvoiceBatchShow = () => (
  <Show>
    <Stack direction="row" justifyContent="space-between">
      <CSVButton prefix="invoice-batches" />
      <DeleteButton />
    </Stack>
    <SimpleShowLayout>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <ArrayField source="invoices">
        <Datagrid>
          <TextField source="email" />
          <TextField source="legalName" />
          <TextField source="contractID" />
          <TextField source="accountNumber" />
          <TextField source="swiftCode" />
          <TextField source="taxID" />
          <ChipField source="serviceType" />
          <ChipField source="legalPriceType" />
          <TextField source="billingAddress.line1" />
          <TextField source="billingAddress.line2" />
          <TextField source="billingAddress.city" />
          <TextField source="billingAddress.zipCode" />
          <TextField source="billingAddress.state" />
          <TextField source="billingAddress.country" />
          <NumberField source="taxPercent" />
          <NumberField source="taxFixed" />
          <TextField source="paymentType" />
          <DateField source="exchangeRateDate" />
          <NumberField source="exchangeRate" />
          <DateField source="issueDate" />
          <TextField source="invoiceID" />
          <NumberField source="invoiceSubtotal" />
          <NumberField source="invoiceHours" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default InvoiceBatchShow;
