import { Stack } from '@mui/material';
import { MAPPING_TYPES, REVIEW_STATUSES } from 'components/resources/Mapping/constants';
import React from 'react';
import moment from 'moment';
import {
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  SimpleForm,
} from 'react-admin';

const toChoices = (types) => Object.keys(types).map((type) => ({ name: type, id: type }));

const timestampToDDMMYYYY = (timestamp) => {
  const val = moment(timestamp);
  return val.isValid() ? val.format('DD/MM/YYYY') : null;
};

const mappingTypeOptions = toChoices(MAPPING_TYPES);
const reviewStatusOptions = toChoices(REVIEW_STATUSES);

const MappingEdit = () => (
  <Edit>
    <SimpleForm>
      <Stack p={2}>
        <ReferenceInput source="transactionId" reference="statement/transactions">
          <AutocompleteInput optionText="data.details.description" />
        </ReferenceInput>
        <Stack direction="row" spacing={2}>
          <SelectInput source="mappingType" choices={mappingTypeOptions} />
          <SelectInput source="reviewStatus" choices={reviewStatusOptions} />
          <BooleanInput source="confirmed" />
          <BooleanInput source="locked" />
        </Stack>
        {/* <SelectArrayInput source="tags" choices=/> */}
        <ArrayInput source="items">
          <SimpleFormIterator inline>
            <NumberInput source="amount" />
            <ReferenceInput source="documentId" reference="documents">
              <AutocompleteInput
                optionText={(doc) => [
                  doc?.document?.issuerName,
                  doc?.document?.id,
                  timestampToDDMMYYYY(doc?.document?.issueDate),
                  [
                    doc?.document?.total,
                    doc?.document?.currency,
                  ].join(' '),
                ].join(' | ')}
                filter={(doc) => !!doc?.document?.id}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="note" multiline />
      </Stack>
    </SimpleForm>
  </Edit>
);

export default MappingEdit;
