import DateFieldUnixtime from 'components/fields/DateFieldUnixtime';
import { usePermissions } from 'providers/AuthProvider';
import React from 'react';
import Payment from '@mui/icons-material/Payment';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  EditButton,
  FunctionField,
  BulkDeleteButton,
  BooleanField,
  SingleFieldList,
  ArrayField,
  ReferenceField,
  TextInput,
  DateInput,
  useRecordContext,
  NumberField,
  SelectInput,
} from 'react-admin';
import { Tooltip } from 'antd';

const MAPPED_CHOICES = [
  { id: 'MAPPED', name: 'Mapped' },
  { id: 'UNDERMAPPED', name: 'Under Mapped' },
  { id: 'OVERMAPPED', name: 'Over Mapped' },
  { id: 'ANY', name: 'Any' },
];

const TEMPLATE_CHOICES = [
  { id: 'ON', name: 'Template' },
  { id: 'OFF', name: 'Not Template' },
  { id: 'ANY', name: 'Any' },
];

const PARSED_CHOICES = [
  { id: 'PARSED', name: 'Parsed' },
  { id: 'UNPARSED', name: 'Not Parsed' },
  { id: 'ANY', name: 'Any' },
];

const documentFilters = [
  <TextInput label="Search" source="q" alwaysOn resettable />,
  <DateInput label="Date from" source="dateFrom" alwaysOn />,
  <DateInput label="Date to" source="dateTo" alwaysOn />,
  <SelectInput label="Mapped" source="mappedStatus" choices={MAPPED_CHOICES} resettable />,
  <SelectInput label="Template" source="template" choices={TEMPLATE_CHOICES} resettable />,
  <SelectInput label="Parsed" source="parsedStatus" choices={PARSED_CHOICES} resettable />,
];

const TransactionWithTooltip = () => {
  const record = useRecordContext();
  return (
    <Tooltip title={[record?._transactionTotal?.toFixed(2), record?.data?.details?.description].join(' | ')}>
      <Payment />
    </Tooltip>
  );
};

const DocumentList = () => {
  const permissions = usePermissions();
  const canEdit = permissions.includes('edit');
  return (
    <List filters={documentFilters}>
      <Datagrid
        bulkActionButtons={(
          <If condition={canEdit}>
            <BulkDeleteButton />
          </If>
        )}
      >
        <DateFieldUnixtime source="timestamp" label="Created" showTime />
        <ArrayField source="_mappedTransactions" label="Transactions">
          <SingleFieldList linkType="show">
            <ReferenceField
              source="transactionId"
              reference="statement/transactions"
              link="show"
            >
              <TransactionWithTooltip />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
        <BooleanField source="_mapped" label="Mapped" />
        <NumberField source="_mappedTotal" label="Mapped Total" />
        <DateField source="document.issueDate" label="Issue Date" />
        <TextField source="document.id" label="Document ID" />
        <TextField source="document.issuerName" label="Issuer Name" />
        <TextField source="document.receiverName" label="Receiver Name" />
        <FunctionField
          label="Total"
          render={(record) => `${record?.document?.total || 0} ${record?.document?.currency || ''}`}
        />
        <TextField source="file.name" label="File name" />
        <TextField source="template" label="Is template?" />
        {/* <TextField source="file.type" label="File type" /> */}
        <ShowButton />
        {canEdit && <EditButton />}
      </Datagrid>
    </List>
  );
};

export default DocumentList;
