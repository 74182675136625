import React, { useState } from 'react';

import {
  useEditContext,
} from 'react-admin';

import SettingsSuggest from '@mui/icons-material/SettingsSuggest';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { parseDocument } from 'utils/api';
import { Spin } from 'antd';

const ParseButton = () => {
  const { record } = useEditContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const documentId = record?.id;

  const handleParse = async () => {
    setDialogOpen(true);
  };

  const handleDialogAccept = async () => {
    try {
      setLoading(true);
      setError(null);
      await parseDocument(documentId);
      setDialogOpen(false);
    } catch (e) {
      const msg = (e?.message || e).toString();
      setError(msg);
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogReject = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogReject}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you really want to parse the PDF again?</DialogContentText>
          {error && <Alert>{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogReject}>Cancel</Button>
          <Button onClick={!loading && handleDialogAccept} autoFocus>
            <>
              <If condition={loading}>
                <Spin />
              </If>
              <If condition={!loading}>
                PARSE NOW!
              </If>
            </>
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleParse}><><SettingsSuggest />PARSE</></Button>
    </>
  );
};

export default ParseButton;
