import React from 'react';
import moment from 'moment';

import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  ChipField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ArrayField,
  BooleanField,
  EditButton,
  FunctionField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const timestampToDDMMYYYY = (timestamp) => {
  const val = moment(timestamp);
  return val.isValid() ? val.format('DD/MM/YYYY') : null;
};

const MappingList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="note" label="Note" />
      <ChipField source="mappingType" label="Mapping type" />
      <ReferenceField
        source="transactionId"
        reference="statement/transactions"
        link="show"
      >
        <TextField source="data.details.description" />
      </ReferenceField>
      <ReferenceArrayField
        label="Documents"
        reference="documents"
        source="_primaryDocuments"
        linkType="show"
      >
        <SingleFieldList linkType="show">
          <FunctionField
            render={(record) => [
              record?.document?.issuerName,
              record?.document?.id,
              timestampToDDMMYYYY(record?.document?.issueDate),
            ].join(' | ')}
          />
        </SingleFieldList>
      </ReferenceArrayField>
      <ArrayField label="Tags" source="tags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <BooleanField label="Confirmed" source="confirmed" />
      <BooleanField label="Locked" source="locked" />
      <ChipField label="Review status" source="reviewStatus" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default MappingList;
