import moment from 'moment';
import { Parser } from 'any-date-parser';
import dayMonthYearFormat from 'any-date-parser/src/formats/dayMonthYear/dayMonthYear';
import dayMonthnameYearFormat from 'any-date-parser/src/formats/dayMonthnameYear/dayMonthnameYear';
import monthnameDayYearFormat from 'any-date-parser/src/formats/monthnameDayYear/monthnameDayYear';
import yearMonthDayFormat from 'any-date-parser/src/formats/yearMonthDay/yearMonthDay';
import monthDayYearFormat from 'any-date-parser/src/formats/monthDayYear/monthDayYear';

export const dateParseAndFormat = {
  parse: (value) => moment(value, 'YYYY-MM-DD').toDate().getTime(),
  format: (value) => moment(value).format('YYYY-MM-DD'),
};

export const numericParsers = [
  {
    parse: (str) => parseFloat(str.replace(/[ ,$€]/g, '')),
    id: 'numeric',
  },
];

export const singleValueJoiner = (values) => values?.[0];

const anyDateParser = (() => {
  const p = new Parser();
  p.addFormat(dayMonthYearFormat);
  p.addFormat(dayMonthnameYearFormat);
  p.addFormat(monthnameDayYearFormat);
  p.addFormat(yearMonthDayFormat);
  return p.exportAsFunction();
})();

const usDateParser = (() => {
  const p = new Parser();
  p.addFormat(monthDayYearFormat);
  return p.exportAsFunction('en-US');
})();

const delimiters = [' ', '/', '-'];

// eslint-disable-next-line complexity
const parseFirstValidToken = (str, tokenParser, validator) => {
  const tokens = (str || '').split(/\s+/);
  for (let i = 0; i < tokens.length; i++) {
    for (let j = 1; j <= 3; j++) {
      // eslint-disable-next-line no-restricted-syntax
      for (const delimiter of delimiters) {
        try {
          const payload = tokens.slice(i, i + j).join(delimiter);
          if (payload.length < 6) {
            // eslint-disable-next-line no-continue
            continue;
          }
          const result = tokenParser(payload);
          if (validator(result)) {
            return result;
          }
        } catch (e) {
          // eslint-disable-next-line no-continue
          continue;
        }
      }
    }
  }
  return null;
};

export const dateParsers = [
  {
    parse: (value) => {
      const parsedDate = parseFirstValidToken(
        value,
        anyDateParser,
        (result) => (result instanceof Date),
      );
      return parsedDate;
    },
    id: 'date-any',
  },
  {
    parse: (value) => {
      const parsedDate = parseFirstValidToken(
        value,
        usDateParser,
        (result) => (result instanceof Date),
      );
      return parsedDate;
    },
    id: 'date-us',
  },
];

export const dateFormatter = (value) => moment(value).format('DD MMM YYYY');
