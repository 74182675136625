import React from 'react';

import {
  TextField,
  NumberField,
  SimpleShowLayout,
  Show,
  ReferenceOneField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
} from 'react-admin';
import {
  Card,
  CardHeader,
  Grid,
} from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const TransactionShow = () => (
  <Show>
    <Grid container spacing={2} p={2}>
      <Grid item>
        <Card raised style={{ padding: '10px' }}>
          <CardHeader title="Transaction" />
          <Grid container spacing={2}>
            <Grid item>
              <SimpleShowLayout>
                <DateFieldUnixtime source="timestamp" label="Date" showTime />
              </SimpleShowLayout>
            </Grid>
            <Grid item>
              <SimpleShowLayout>
                <NumberField source="data.amount.value" label="Charged Amount" />
              </SimpleShowLayout>
            </Grid>
            <Grid item>
              <SimpleShowLayout>
                <TextField source="data.amount.currency" label="Balance Currency" />
              </SimpleShowLayout>
            </Grid>
            <Grid item>
              <SimpleShowLayout>
                <TextField source="data.details.description" label="Description" />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
    <Grid container spacing={4} p={2}>
      <Grid item alignItems="stretch">
        <h3 style={{ padding: '0 15px' }}>Amount details</h3>
        <SimpleShowLayout>
          <TextField source="data.amount.currency" label="Balance Currency" />
          <NumberField source="data.amount.value" label="Charged Amount" />
          <TextField source="data.amount.currency" label="Balance Currency" />
          <NumberField source="data.totalFees.value" label="Charged Fee" />
          <TextField source="data.totalFees.currency" label="Fee Currency" />
          <NumberField source="data.exchangeDetails.toAmount.value" label="Payment Amount" />
          <TextField source="data.exchangeDetails.toAmount.currency" label="Payment Currency" />
        </SimpleShowLayout>
      </Grid>
      <Grid item alignItems="stretch">
        <h3 style={{ padding: '0 15px' }}>Transfer details</h3>
        <SimpleShowLayout>
          <TextField source="data.details.recipient.name" label="Recepient Name" />
          <TextField source="data.details.recipient.bankAccount" label="Recepient Bank Account" />
          <TextField source="data.details.type" label="Transaction Type" />
          <TextField source="data.details.senderName" label="Sender Name" />
          <TextField source="data.details.senderAccount" label="Sender Bank Account" />
          <TextField source="data.details.paymentReference" label="Payment Reference" />
          <TextField source="data.referenceNumber" label="Transaction Reference" />
        </SimpleShowLayout>
      </Grid>
      <Grid item alignItems="stretch">
        <h3 style={{ padding: '0 15px' }}>Merchant details</h3>
        <SimpleShowLayout>
          <TextField source="data.details.merchant.name" label="Merchant Name" />
          <TextField source="data.details.merchant.category" label="Merchant Category" />
          <TextField source="data.details.merchant.country" label="Merchant Country" />
          <TextField source="data.details.cardLastFourDigits" label="Last card digits" />
        </SimpleShowLayout>
      </Grid>
      <Grid item alignItems="stretch">
        <h3 style={{ padding: '0 15px' }}>Mapping Items</h3>
        <SimpleShowLayout>
          <ReferenceOneField
            label="Mapping"
            target="transactionId"
            reference="mappings"
            link="show"
          >
            <ArrayField source="items" label="Mapping Items">
              <Datagrid bulkActionButtons={false}>
                <NumberField source="amount" />
                <ReferenceField
                  source="documentId"
                  reference="documents"
                  link="show"
                >
                  <ChipField label="Document" source="document.id" />
                </ReferenceField>
              </Datagrid>
            </ArrayField>
          </ReferenceOneField>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  </Show>
);

export default TransactionShow;
